import { NpmInstitutionalPage } from '@plurix/ecom-pages'
import React from 'react'
import { DownloadIcon } from 'src/assets/DownloadIcon'
import useCmsInstitutional from 'src/hooks/useCmsInstitutional'

import pdf from '../../../static/institucional/termos_e_condicoes_gerais_de_uso.pdf'
import { storeUrl } from '../../../store.config'
import '../../styles/pages/termos.scss'

const Terms = () => {
  const { termsBanners } = useCmsInstitutional()
  const storeUrlReplaced = storeUrl.replace('https://', '')

  return (
    <NpmInstitutionalPage
      title="Termos e Condições Gerais de Uso"
      banners={termsBanners}
    >
      <div className="terms-container">
        <p>
          A plataforma do website “{storeUrlReplaced}” de propriedade da Boa
          Supermercados, doravante denominado como “Boa”, mantida e
          disponibilizado pela IRMÃOS BOA LTDA, pessoa jurídica de direito
          privado, inscrita no Cadastro Nacional de Pessoas Jurídicas
          (“CNPJ/MF”) sob o n. 50.948.371/0008-44, com sede na Avenida Olívio
          Roncoletta, n. 465, Galpão 01, 03, 05, 06,07 e 08, Jundiaí/SP, CEP:
          13.214-306, tem como destino o cadastro de seus clientes da Boa e
          Programa de Descontos e Fidelidade, para que possam realizar pesquisas
          de preços, usufruir dos serviços disponibilizados e dos ambientes
          oferecidos pela Boa, encontrando-se disponível de acesso através do
          seguinte website:{' '}
          <a href={storeUrl} target="_blank" rel="noreferrer">
            {storeUrlReplaced}
          </a>
        </p>
        <p>
          Considerando o compromisso assumido pela Boa Supermercados em busca da
          qualidade de seus serviços, da informação, da transparência e da
          segurança em suas operações realizadas através do presente Portal com
          seus clientes e consumidores, as atividades desenvolvidas seguem de
          maneira estrita o presente Termos de Uso e Aceite para o conhecimento
          das condições gerais de uso deste.
        </p>
        <p>
          <strong>
            O conteúdo dos presentes Termos de Uso e Aceite deve ser analisado
            de maneira conjunta com o disposto em Aviso de Privacidade (Política
            Externa de Privacidade), disponível para acesso por meio do seguinte
            documento:
          </strong>
        </p>
        <p>
          <a
            href={pdf}
            download="termos_e_condicoes_gerais_de_uso.pdf"
            type="application/pdf"
          >
            <DownloadIcon />
            Baixar Documento Completo
          </a>
        </p>
      </div>
    </NpmInstitutionalPage>
  )
}

export default Terms
